<template>
  <div class="background">
    <!-- Plans -->
    <Plans></Plans>
    <SmartSizeFooter></SmartSizeFooter>
    <!-- Plans -->
  </div>
</template>
<script>
import Plans from "@/components/Plans/Plans.vue";

export default {
  data() {
    return {};
  },
  components: {
    Plans,
  },
};
</script>

<style>
.background {
  background-color: #f3f5f7;
  min-height: 100vh;
}
</style>