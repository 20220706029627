<template>
  <v-col sm="6" md="4">
    <v-card
      class="d-flex flex-column"
      height="550"
      elevation="5"
      :disabled="disabled"
    >
      <v-card-title class="justify-center blue--text">
        {{ plan.title }}
      </v-card-title>

      <v-card-text>
        <v-row class="text-center" align="center">
          <v-col class="display-1"> {{ plan.price }} </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-center">
        <v-btn
          class="my-5"
          width="180px"
          height="45px"
          @click="selectPlan(plan.title, plan.plan_id)"
          depressed
          dark
        >
          Select
        </v-btn>
      </v-card-actions>

      <v-list class="transparent text-center">
        <v-list-item v-for="item in plan.feature" :key="item">
          <v-list-item-title class="ma-0"
            ><p class="text-wrap">{{ item }}</p></v-list-item-title
          >
        </v-list-item>
      </v-list>
    </v-card>
  </v-col>
</template>
  
 <script>
export default {
  props: ["plan", "disabled"],
  data() {
    return {};
  },
  methods: {
    selectPlan(title, id) {
      this.$emit("selectedPlan", title, id);
    },
  },
};
</script>
 
 