<template>
  <v-container>
    <v-row class="mt-5">
      <v-col cols="12" sm="6">
        <h1 left class="text-left">Plans</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <h4 left class="text-left">
          Your current plan is:
          <span style="color: #4c7b9a">{{ currentPlan }}</span>
        </h4>
        <!-- Plan: Free Forever  -->
        <template v-if="currentPlanID == 1">
          <p>
            As our Thank You for installing SmartSize, you are entitled to the
            Free Plan, with unlimited Size Charts.
          </p>
        </template>

        <!-- Plan: Free legacy, with 4 published charts  -->
        <template v-else-if="currentPlanID == 2">
          <p>
            You have our introductory Free Plan, with up to 4 published Size
            Charts.
          </p>
        </template>

        <!-- Plan: Growth legacy, at USD 4.99 / month  -->
        <template v-else-if="currentPlanID == 3">
          <p>
            You have the Growth Plan, with an introductory price of USD 4.99 /
            month.
          </p>
        </template>

        <!-- Plan: Free Trial  -->
        <template v-else-if="currentPlanID == 4">
          <p>
            You are currently on a 15-day trial of our Growth Plan. After the
            trial finishes, a countdown will appear on your charts. To remove
            the countdown, please upgrade your plan.
          </p>
        </template>
      </v-col>
    </v-row>

    <v-row d-flex justify-md="center">
      <plans-item
        class="max-width-card"
        v-for="(plan, i) in plans"
        :key="i"
        @selectedPlan="selectedPlan"
        :plan="plan"
        :disabled="
          currentPlanID == 1 ||
          currentPlanID == plan.plan_id ||
          (currentPlanID == 3 && plan.plan_id == 6) ||
          (currentPlanID == 2 && plan.plan_id == 5)
        "
      >
      </plans-item>
    </v-row>
  </v-container>
</template>
<script>
import PlansItem from "./PlansItem.vue";

let data = null;

export default {
  beforeMount() {
    this.fetchData();
  },
  data() {
    return {
      plans: [],
      currentPlan: "",
      currentPlanID: null,
    };
  },
  components: {
    PlansItem,
  },
  methods: {
    async selectedPlan(title, id) {
      this.currentPlan = title;
      this.$store.commit("updateShopProfile", {
        ...this.$store.getters.getShopProfile,
        popup_setting_show_message: "",
      });

      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/plan/setplan", {
          params: { shop: localStorage.getItem("shop"), id },
        })
        .then((response) => (this.plan = response.data))
        .catch((error) => console.log(error));
      if (this.plan.plan == "paid") {
        top.window.location = this.plan.redirect_url;
      } else {
        this.$router.push("/plan_downgrade");
      }
      //this.$router.push('/');
    },
    async fetchData() {
      await this.$http
        .get(process.env.VUE_APP_SERVER_URL + "/plan", {
          params: { shop: localStorage.getItem("shop") },
        })
        .then((response) => (this.data = response.data))
        .catch((error) => console.log(error));
      console.log(this.data);
      this.currentPlan = this.data.currentPlan;
      this.currentPlanID = this.data.currentPlanId;
      this.plans = this.data.plans;
    },
  },
};
</script>
<style scoped>
.max-width-card {
  max-width: 400px;
}
</style>